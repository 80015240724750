<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Nhập sản phẩm combo'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <span>
                        <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file
                        excel đúng format, hoặc có thể tải ở
                        <a :href="urlExcel">đây</a>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span> <b>Bước 2:</b> Tiến hành Import </span>
                    <div style="padding-top: 15px">
                      <b-form-file
                        placeholder="Hãy chọn một tập tin"
                        @change="selectFile"
                        v-model="file"
                        ref="file-input"
                        class="mb-2"
                      ></b-form-file>
                    </div>
                    <b-progress
                      variant="success"
                      :value="progress"
                      :max="max"
                      :striped="striped"
                      show-value
                    >
                      <b-progress-bar
                        :value="progress"
                        :label="`${progress.toFixed(2)}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-col>
                </b-row>
              </b-container>
              <!-- End input session -->

              <b-container class="mt-10" v-show="importItems.length > 0">
                <h5>Danh sách sản phẩm combo từ file excel</h5>
                <b-table
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fields"
                  :items="importItems"
                >
                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="convertPrice(row.item.sellingPrice)"></span>
                    </div>
                  </template>
                  <template v-slot:cell(quantity)="row">
                    <div style="text-align: end">
                      <span v-text="row.item.quantity"></span>
                    </div>
                  </template>
                </b-table>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <div class="bv-example-row container">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="importProduct"
                  >Lưu</b-button
                >
                <router-link to="/products" tag="button">
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    >Hủy</b-button
                  >
                </router-link>
              </b-row>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import xlsx from 'xlsx';
import ApiService from '@/core/services/api.service';
import axios from 'axios';
import { URL_IMPORT_PRODUCT_COMBO } from '../../../utils/constants';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Tên combo',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
        {
          key: 'name',
          label: 'Sản phẩm con',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      max: 100,
      striped: true,
      uploading: false,
      urlExcel: URL_IMPORT_PRODUCT_COMBO,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm combo', route: '/products' },
      { title: 'Nhập sản phẩm combo' },
    ]);
  },
  methods: {
    convertPrice: function (number) {
      let result = '';
      if (number) {
        if (number.trim() !== 'undefined')
          result = new Intl.NumberFormat('vn-VN').format(number);
      }
      return result;
    },
    importProduct: async function () {
      if (!this.file) {
        return;
      }
      this.uploading = true;

      var config = {
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      ApiService.setHeader();
      axios
        .post('product/import-product-combo', this.excellist, config)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.clearFiles();
            this.$router.push({
              name: 'list-products',
              params: { importProductComboSuccess: true },
            });
          } else {
            this.makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data.data.message;
          this.makeToastFaile(errorMessage);
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase().trim())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              productName: element['Tên combo'],
              sellingPrice: element['Giá bán'] + '',
              name: element['Sản phẩm con'],
              quantity: element['Số lượng'],
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
